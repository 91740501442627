import axios from "axios";
import { Toast } from "vant";
import "vant/es/toast/style/index.js";

// import {getToken, removeToken} from '@/utils/auth'
import { router } from "../router.js";
const instance = axios.create({
  baseURL: window._config.apiBaseUrl + "/api",

  // baseURL: process.env.VUE_APP_BASE_API + '/api',
  // timeout: 0,
  // headers: { 'X-Custom-Header': 'foobar' }
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data;
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      // console.log(2)该用户无权限访问
      Toast.fail(data.error.message);
    }
    if (
      error.response.status === 401 &&
      !(data.result && data.result.isLogin)
    ) {
      // 未登录，或登录失效
      Toast.fail({
        message: "未授权",
        description: "授权验证失败",
      });
      let token = localStorage.getItem("storage_key");
      if (token) {
        localStorage.removeItem("storage_key");
        localStorage.removeItem("userData");
        if (router.currentRoute.path !== "/login") {
          router.push({ name: "login" });
        }
      } else {
        if (router.currentRoute.path !== "/login") {
          router.push({ name: "login" });
        }
      }

      //   notification.error({
      //     message: '未授权',
      //     description: '授权验证失败'
      // })
      // if (token) {
      //     store.dispatch('Logout').then(() => {
      //         setTimeout(() => {
      //             this.$router.push({ path: '/login' })
      //             // window.location.reload()
      //         }, 1500)
      //     })
      // }
    }
  }
  return Promise.reject(error);
};
// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let storage_key = localStorage.getItem("storage_key")
    ? localStorage.getItem("storage_key")
    : "";
  // config.headers={
  //   'content-type': 'application/json',
  //   // 默认值
  //   'Access_Token': storage_key
  // }
  config.headers["Authorization"] = "Bearer " + storage_key;

  return config;
}, errorHandler);

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // console.log(response,'response')
  // Do something with response data
  // console.log('routerrouterrouterrouter',router)
  return response.data;
}, errorHandler);
export default instance;
